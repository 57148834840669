@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anybody:wght@700&display=swap');
body {
  margin: 0;
  padding: 0;
  font-family: 'Anybody', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
}

* {
  font-family: 'Anybody', cursive;
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgb(72, 140, 220) !important;
}

.MuiInputLabel-root {
  color: rgb(72, 140, 220) !important;
}

.button-div > button{
  color: black;
}
